import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Filter from "../../../Filters/Filter";
import PostDataContainer from "../../../Common/PostDataContainer";
import Footer from "../../../Common/Footer";
import { editor_in_all } from "../../../../store/actions/postAction";
import EditorNavBar from "./EditorNavBar";
import SubNavBar from "./SubNavBar";
import PostArea from "./PostArea";
import DropDown from "./DropDown";

export class InReview extends Component {
  constructor() {
    super();
    this.state = {
      all_drafts: [],
    };
  }
  /******************************
   * @DESC - LIFECYCLE METHODS
   ******************************/
  // componentDidMount() {
  //   this.props.editor_in_all({
  //     post_type: "News",
  //     _id: this.props.auth.user._id,
  //     fname: this.props.auth.user.fname,
  //     lname: this.props.auth.user.lname,
  //   });
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.articleType !== this.props.articleType) {
  //     console.log("article type changed");
  //     this.props.editor_in_all({
  //       post_type:
  //         this.props.articleType.charAt(0).toUpperCase() +
  //         this.props.articleType.slice(1),
  //       _id: this.props.auth.user._id,
  //       fname: this.props.auth.user.fname,
  //       lname: this.props.auth.user.lname,
  //     });
  //   }
  // }

  componentDidMount() {
    this.fetchPosts();
  }

  componentDidUpdate(prevProps) {
    // Re-fetch posts if articleType or sorting parameters change
    if (
      prevProps.articleType !== this.props.articleType ||
      prevProps.sortBy !== this.props.sortBy ||
      prevProps.sortOrder !== this.props.sortOrder
    ) {
      this.fetchPosts();
    }
  }

  // Fetch posts with sorting parameters
  fetchPosts = () => {
    console.log(
      "Fetching posts with :",
      this.props.sortBy,
      this.props.sortOrder,
      this.props.articleType
    );
    this.props.editor_in_all(
      {
        post_type:
          this.props.articleType.charAt(0).toUpperCase() +
          this.props.articleType.slice(1),
        // post_type: "News",
        _id: this.props.auth.user._id,
        fname: this.props.auth.user.fname,
        lname: this.props.auth.user.lname,
      },
      this.props.sortBy || "content_last_updated_on.date", // Default field
      this.props.sortOrder || "desc" // Default order
    );
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextState.all_drafts !== nextProps.posts.editor_in_all) {
      return {
        all_drafts: nextProps.posts.editor_in_all,
      };
    }
    return null;
  }
  /******************************
   * @DESC - LIFECYCLE METHODS
   ******************************/
  /******************************
   * @DESC - OPEN POST METHODS
   ******************************/
  open_post = (id) => (e) => {
    this.props.history.push(`/posts/editors/${id}`);
  };
  render() {
    let data = this.state.all_drafts;
    // let data = [];
    // data = this.props.posts.all_published;
    return (
      <div className="container-fluid">
        <SubNavBar
          user={this.props.auth.user}
          active={"Editor"}
          path={this.props.history.location.pathname}
        />
        <div className="row">
          <div className="col-sm-12">
            <div className="margin_top"></div>
          </div>
        </div>

        <div className="nave_container">
          <EditorNavBar path={this.props.history.location.pathname}
            articleType={this.props.articleType} />
          <DropDown
            active={
              this.props.articleType.charAt(0).toUpperCase() +
              this.props.articleType.slice(1)
            }
            pageName="edit-all"
            path={this.props.history.location.pathname}
            history={this.props.history}
          />
        </div>
        <div className="border_line"></div>
        {this.props.auth.loader ? (
          <div className="modal_upload_spinner" style={{ marginTop: "10px" }}>
            <i className="ml-2 fa fa-spinner fa-pulse " aria-hidden="true"></i>
          </div>
        ) : null}
        <Filter />
        {data.length === 0 ? <PostDataContainer /> : null}
        {data.length !== 0 ? (
          <PostArea data={data} open_post={this.open_post} />
        ) : null}

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors.errors,
  posts: state.posts,
  sortBy: state.posts.sortBy, // Get sorting from Redux
  sortOrder: state.posts.sortOrder,
});

export default connect(mapStateToProps, { editor_in_all })(
  withRouter(InReview)
);
