import React, { useEffect, useState } from "react";

export const FilterPanel = ({
  onClose,
  onApplyFilters,
  defaultSortBy = "content_last_updated_on.date",
  defaultSortOrder = "desc",
}) => {

  const [sortBy, setSortBy] = useState(defaultSortBy); // Default sort field
  const [sortOrder, setSortOrder] = useState(defaultSortOrder); // Default sort order

  // Update state if default values change (e.g., after applying filters)
  useEffect(() => {
    setSortBy(defaultSortBy);
    setSortOrder(defaultSortOrder);
  }, [defaultSortBy, defaultSortOrder]);

  const applyFilters = () => {
    onApplyFilters(sortBy, sortOrder);
    onClose();
  };

  return (
    <div className='panel_filter_full_height'>
      <div className='close_filter_and_clear_filters'>
        <div onClick={onClose} className='clear_filter_icon'><i className="fa fa-times" aria-hidden="true"></i></div>
        {/* Apply Button */}
        <button className='clear_filter' onClick={applyFilters}>Apply Filters</button>
      </div>
      {/* Sorting Options */}
      <div className='publication_main_filter_container'>
        <div className='publication_filter_label'>Sort by Date updated
          <div className='sort_by_publication_container'>

            <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}