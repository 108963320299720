import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EditorNavBar from "./EditorNavBar";
import SubNavBar from "./SubNavBar";
import PostArea from "./PostArea";
import Filter from "../../../Filters/Filter";
import PostDataContainer from "../../../Common/PostDataContainer";
import Footer from "../../../Common/Footer";
import { get_editor_in_publsihed } from "../../../../store/actions/postAction";
import DropDown from "./DropDown";

export class Published extends Component {
  constructor() {
    super();
    this.state = {
      all_published: [],
      // sortBy: "content_last_updated_on.date", // Default sorting field
      // sortOrder: "desc", // Default sorting order
    };
  }

  // /******************************
  //  * @DESC - LIFECYCLE METHODS
  //  ******************************/
  // componentDidMount() {
  //   this.props.get_editor_in_publsihed({
  //     post_type: "News",
  //     _id: this.props.auth.user._id,
  //     fname: this.props.auth.user.fname,
  //     lname: this.props.auth.user.lname,
  //   },
  //     this.state.sortBy,  // Send sorting field
  //     this.state.sortOrder // Send sorting order
  //   );
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.articleType !== this.props.articleType) {
  //     console.log("article type changed");
  //     this.props.get_editor_in_publsihed({
  //       post_type:
  //         this.props.articleType.charAt(0).toUpperCase() +
  //         this.props.articleType.slice(1),
  //       _id: this.props.auth.user._id,
  //       fname: this.props.auth.user.fname,
  //       lname: this.props.auth.user.lname,
  //     },
  //       this.state.sortBy,  // Send sorting field
  //       this.state.sortOrder // Send sorting order
  //     );
  //   }
  // }

  componentDidMount() {
    this.fetchPosts();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.articleType !== this.props.articleType ||
      prevProps.sortBy !== this.props.sortBy ||
      prevProps.sortOrder !== this.props.sortOrder
    ) {
      this.fetchPosts(); // Re-fetch if sorting or article type changes
    }
  }

  fetchPosts = () => {

    // const { sortBy, sortOrder } = this.props;
    this.props.get_editor_in_publsihed(
      {
        post_type:
          this.props.articleType.charAt(0).toUpperCase() +
          this.props.articleType.slice(1),
        // post_type: "News",
        _id: this.props.auth.user._id,
        fname: this.props.auth.user.fname,
        lname: this.props.auth.user.lname,
      },
      this.props.sortBy || "content_last_updated_on.date",
      this.props.sortOrder || "desc"
    );
  };

  // handleSortChange = (sortBy, sortOrder) => {
  //   this.setState({ sortBy, sortOrder });
  // };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextState.all_published !== nextProps.posts.editor_published) {
      return {
        all_published: nextProps.posts.editor_published,
      };
    }
    return null;
  }
  /******************************
   * @DESC - LIFECYCLE METHODS
   ******************************/
  /******************************
   * @DESC - OPEN POST METHODS
   ******************************/
  open_post = (id) => (e) => {
    this.props.history.push(`/posts/editors/${id}`);
  };
  render() {
    let data = this.state.all_published;
    return (
      <div className="container-fluid">
        <SubNavBar
          user={this.props.auth.user}
          active={"Editor"}
          path={this.props.history.location.pathname}
        />
        <div className="row">
          <div className="col-sm-12">
            <div className="margin_top"></div>
          </div>
        </div>

        <div className="nave_container">
          <EditorNavBar
            path={this.props.history.location.pathname}
            articleType={this.props.articleType}
          />
          <DropDown
            active={
              this.props.articleType.charAt(0).toUpperCase() +
              this.props.articleType.slice(1)
            }
            pageName="published"
            // active={"News"}
            path={this.props.history.location.pathname}
            history={this.props.history}
          />
        </div>
        <div className="border_line"></div>
        {this.props.auth.loader ? (
          <div className="modal_upload_spinner" style={{ marginTop: "10px" }}>
            <i className="ml-2 fa fa-spinner fa-pulse " aria-hidden="true"></i>
          </div>
        ) : null}

        {/* <Filter onSortChange={this.handleSortChange} /> */}
        <Filter />

        {data.length === 0 ? <PostDataContainer /> : null}
        {data.length !== 0 ? (
          <PostArea data={data} open_post={this.open_post} />
        ) : null}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors.errors,
  posts: state.posts,
  sortBy: state.posts.sortBy, // Get sorting from Redux
  sortOrder: state.posts.sortOrder,
});

export default connect(mapStateToProps, {
  get_editor_in_publsihed
})(withRouter(Published));
