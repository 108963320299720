import React, { Component, useState } from 'react';
import Input from '../Common/InputComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FilterPanel } from './FilterPanel';
import { updateSortingPreferences } from '../../store/actions/postAction';

export class Filter extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            // sortBy: "content_last_updated_on.date", // Default sort field
            // sortOrder: "desc", // Default sort order
        }
    }
    filterToggler = () => {
        this.setState((prevState) => ({
            show: !prevState.show
        }));
    };

    handleApplyFilters = (sortBy, sortOrder) => {

        // Null check: Do nothing if no values are selected
        if (!sortBy?.trim() || !sortOrder?.trim() || !['asc', 'desc'].includes(sortOrder.toLowerCase()))
            return;

        // Update the parent's state with the new sorting preferences
        // this.setState({ sortBy, sortOrder });

        // this.props.onSortChange(sortBy, sortOrder); // Pass sorting options to parent component
        // Dispatch sorting action instead of calling props function
        this.props.updateSortingPreferences(sortBy, sortOrder);
    };

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='filter_main_container'>
                            <div onClick={this.filterToggler}><i className="fa fa-filter" aria-hidden="true"></i> &nbsp;Filters</div>
                        </div>
                    </div>
                </div>
                {/* { this.state.show ? <FilterPanel onClose={ this.filterToggler } /> : null } */}
                {this.state.show && (
                    <FilterPanel
                        onClose={this.filterToggler}
                        onApplyFilters={this.handleApplyFilters} // Pass function to FilterPanel
                        defaultSortBy={this.props.sortBy}
                        defaultSortOrder={this.props.sortOrder}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.errors,
    sortBy: state.posts.sortBy, // Get sorting from Redux
    sortOrder: state.posts.sortOrder,
})

export default connect(mapStateToProps, { updateSortingPreferences })(withRouter(Filter));
