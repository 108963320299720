//get posts draft, assigned, unassigned, update
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Filter from "../../../Filters/Filter";
import PostDataContainer from "../../../Common/PostDataContainer";
import Footer from "../../../Common/Footer";
import { get_author_post_drafts } from "../../../../store/actions/postAction";
import RenderPost from "../../RenderPost/RenderPost";
import Spinner from "./../../../Common/Spinner";
import DropDown from "./DropDown";
import AuthorNavBar from "./AuthorNavBar";
import SubNavBar from "./SubNavBar";
class Draft extends Component {
  constructor() {
    super();
    this.state = {
      all_drafts: [],
    };
  }
  /******************************
   * @DESC - LIFECYCLE METHODS
   ******************************/
  componentDidMount() {
    this.props.get_author_post_drafts({
      post_type:
        this.props.articleType.charAt(0).toUpperCase() +
        this.props.articleType.slice(1),
      _id: this.props.auth.user._id,
      fname: this.props.auth.user.fname,
      lname: this.props.auth.user.lname,
    },
      this.props.sortBy || "content_last_updated_on.date", // Default sorting field
      this.props.sortOrder || "desc" // Default sorting order
    );
  }

  //if props have changed then we will make a network request
  componentDidUpdate(prevProps) {
    if (prevProps.articleType !== this.props.articleType) {
      this.props.get_author_post_drafts({
        post_type:
          this.props.articleType.charAt(0).toUpperCase() +
          this.props.articleType.slice(1),
        _id: this.props.auth.user._id,
        fname: this.props.auth.user.fname,
        lname: this.props.auth.user.lname,
      },
        this.props.sortBy || "content_last_updated_on.date", // Default sorting field
        this.props.sortOrder || "desc" // Default sorting order
      );
    }
  }

  // componentDidMount() {
  //   this.fetchPosts(); // Fetch posts with sorting when component mounts
  // }

  // componentDidUpdate(prevProps) {
  //   // If sorting parameters or articleType changes, re-fetch posts
  //   if (
  //     prevProps.articleType !== this.props.articleType ||
  //     prevProps.sortBy !== this.props.sortBy ||
  //     prevProps.sortOrder !== this.props.sortOrder
  //   ) {
  //     console.log("Sorting or article type changed. Fetching posts...");
  //     this.fetchPosts();
  //   }
  // }

  // fetchPosts = () => {
  //   console.log("Fetching posts with:", this.props.sortBy, this.props.sortOrder);

  //   this.props.get_author_post_drafts(
  //     {
  //       post_type: this.props.articleType,
  //       _id: this.props.auth.user._id,
  //       fname: this.props.auth.user.fname,
  //       lname: this.props.auth.user.lname,
  //     },
  //     this.props.sortBy || "content_last_updated_on.date", // Default sorting field
  //     this.props.sortOrder || "desc" // Default sorting order
  //   );
  // };

  // // Update local state when Redux store changes.
  // static getDerivedStateFromProps(nextProps, nextState) {
  //   if (nextState.all_drafts !== nextProps.posts.author_draft) {
  //     console.log("Draft component: Updating local state from Redux store.");
  //     return {
  //       all_drafts: nextProps.posts.author_draft,
  //     };
  //   }
  //   return null;
  // }

  open_post = (id) => (e) => {
    this.props.history.push(`/posts/authors/${id}`);
  };

  render() {
    return (
      <div className="container-fluid">
        <SubNavBar
          user={this.props.auth.user}
          active={"Author"}
          path={this.props.history.location.pathname}
          articleType={this.props.articleType}
        />
        <div className="row">
          <div className="col-sm-12">
            <div className="margin_top"></div>
          </div>
        </div>

        <div className="nave_container">
          <AuthorNavBar
            path={this.props.history.location.pathname}
            articleType={this.props.articleType}
          />
          <DropDown
            active={
              this.props.articleType.charAt(0).toUpperCase() +
              this.props.articleType.slice(1)
            }
            pageName={"draft"}
            path={this.props.history.location.pathname}
            history={this.props.history}
          />
        </div>
        <div className="border_line"></div>
        <Spinner loading={this.props.auth.loader} />

        <Filter />
        {this.props.posts.author_draft.length === 0 ? <PostDataContainer /> : null}
        {this.props.posts.author_draft.length !== 0 ? (
          <PostArea data={this.props.posts.author_draft} open_post={this.open_post} />
        ) : null}

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors.errors,
  posts: state.posts,
  sortBy: state.posts.sortBy, // Get sorting field from Redux
  sortOrder: state.posts.sortOrder, // Get sorting order from Redux
});

export default connect(mapStateToProps, { get_author_post_drafts })(
  withRouter(Draft)
);

export const PostArea = ({ data, open_post }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="post_area_container">
          {data.map((post, index) => (
            <RenderPost
              onClick={
                post.content_status === "Unassigned" ||
                  post.content_status === "Assigned" ||
                  post.content_status === "Published" ||
                  post.content_status === "Rejected"
                  ? () => window.alert("These post cannot be opened!")
                  : open_post(post._id)
              }
              key={index}
              post={post}
              background={
                post.content_status === "Draft" ||
                  post.content_status === "Unassigned"
                  ? "background_yellow"
                  : post.content_status === "Update" ||
                    post.content_status === "Rejected"
                    ? "background_red"
                    : post.content_status === "Assigned"
                      ? "background_grey"
                      : "backgroud_green"
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
