import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmpty from "../../../utils/isEmpty";
import Loader from "../../Common/Loader";
import { SERVER_NAME, SERVER_TWO } from "../../../ServerUrl";
import {
  //For pricesheet
  create_and_get_price_sheet,
  calculate_update_pricesheet,
  update_pricesheet,
  get_all_source_company_and_source_persons,
  get_refresh_pricesheet,
  // price_sheet_selected_trade_log,
  // get_previous_sheet,
  // get_previous_sheet_unassigned,
  // get_all_dpcs,
  // create_new_price_sheet,
  // get_latest_dpc_price,
  send_for_review,
  get_old_source_entries,
} from "../../../store/actions/priceSheetAction";
import {
  get_all_pricing_reporter,
  //get_all_journalists
} from "../../../store/actions/authAction";
import { get_editorial_emails } from "../../../store/actions/editorialEmailAction";
import RollOver from "./Common/RollOver";
// import { create_sheet_for_vwa } from '../../../store/actions/excelJSONActions';
import SuccessMessage from "../../Common/SuccessMessage";
import Footer from "../../Common/Footer";
// REUSBALE COMPS
import DPCName from "./ReusableComponentsAssesmentRange/NameOfScrapMetal";
import CalculateSection from "./ReusableComponentsAssesmentRange/VWA/CalculateSection";
import NewValuesSection from "./ReusableComponentsAssesmentRange/VWA/NewValueSection";
import SourceInputContainer from "./ReusableComponentsAssesmentRange/VWA/SourceInputContainer";
import CalculateCommentSection from "./ReusableComponentsAssesmentRange/CalculatedCommentsSection";
import CalculatedTrendSection from "./ReusableComponentsAssesmentRange/CalculatedTrendSection";
import SelectSourceCompany from "./ReusableComponentsAssesmentRange/SelectSourceCompany";
import SelectSourcePerson from "./ReusableComponentsAssesmentRange/SelectSourcePerson";
import SelectTypeAndTime from "./ReusableComponentsAssesmentRange/SelectTypeAndTime";
import SourceCommentsContainer from "./ReusableComponentsAssesmentRange/SourceInputComments";
import SourcePriceType from "./ReusableComponentsAssesmentRange/SourcePriceTypeContainer";
import AssignAuthors from "./ReusableComponentsAssesmentRange/AssignNextAuthor";
// import AuthorNotes from './ReusableComponentsAssesmentRange/AuthorNotes';
import SubmitButton from "./ReusableComponentsAssesmentRange/SubmitButton";
// import SaveButton from './ReusableComponentsAssesmentRange/SaveButton';
import { parseISO, format } from "date-fns"; //format(new Date(), "yyyy-MM-dd'T'HH-mm"))
import { AddNewSource } from "../Sources/AddNewSource/AddNewSource";
// import axios from 'axios';
const Validator = require("validator");
const validatePrice = (price) => {
  let errors = {};

  for (let i = 0; i < price.length; i++) {
    if (Validator.isEmpty(price[i].prior_price_value)) {
      errors.prior_price_value = `prior price is very important !!`;
      window.alert(
        `prior price is very important at  ${price[i].dpc_website_name}`
      );
      break;
    }

    if (Validator.isEmpty(price[i].new_price_value)) {
      errors.new_price_value = `new price is very important !!`;
      window.alert(
        `Please calculate the index for ${price[i].dpc_website_name}. The new price field is currently empty.`
      );
      break;
    }

    if (
      !Validator.isEmpty(price[i].new_price_value) &&
      !Validator.isEmpty(price[i].prior_price_value)
    ) {
      let trend = (trend = parseFloat(
        (price[i].new_price_value - price[i].prior_price_value) /
          (price[i].prior_price_value / 100)
      ).toFixed(2));

      if (trend > 20 || trend < -20) {
        if (Validator.isEmpty(price[i].override_trend_note)) {
          errors.override_trend_note = `${price[i].dpc_website_name} The percentage change is greater than normal so please add the reason and justification here:`;
          window.alert(
            `${price[i].dpc_website_name} The percentage change is greater than normal so please add the reason and justification here:`
          );
          break;
        }
      }
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export class VolumeWeightedAverage extends Component {
  constructor() {
    super();
    this.state = {
      priceSheet: [],
      new_price_traidelog: [],
      submitToReview: true,
      // hasCreatedPriceSheet:true,
      editorEmail: "",
      success: false,
      copyed: false,
      copyedData: "",
    };
  }
  /***********************************
   * @DESC - LIFECYCLE METHODS
   *
   ***********************************/
  componentDidMount() {
    this.props.create_and_get_price_sheet(this.props.match.params.id);
    this.props.get_all_source_company_and_source_persons(
      this.props.match.params.id
    );
    // this.props.price_sheet_selected_trade_log(this.props.match.params.id);
    // this.props.get_all_dpcs(this.props.match.params.id);
    // this.props.get_previous_sheet(this.props.match.params.id);
    // this.props.get_previous_sheet_unassigned(this.props.match.params.id);
    this.props.get_editorial_emails();
    this.props.get_all_pricing_reporter();
    //this.props.get_all_journalists();
    // this.props.get_latest_dpc_price(this.props.match.params.id);
    this.props.get_old_source_entries(this.props.match.params.id);
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (
      !nextProps.priceSheet.isPreviousSheetNotThere &&
      !isEmpty(nextProps.priceSheet.previous_sheet) &&
      !nextState.hasSetData
    ) {
      return {
        ...nextState,
        ...nextProps.priceSheet, // ...nextProps.priceSheet.previous_sheet[0],
        hasSetData: true,
      };
    }
    return null;
  }

  timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  /******************************
   * @DESC - LIFECYCLE METHODS
   * @DESC - ENDS
   ******************************/
  /*******************************
   * @DESC - ONCHANGE HANDLERS
   * @DESC - HANDLER ONE
   *******************************/
  onChangehandlerOne = (index) => (e) => {
    let price_sheet = this.state.previous_sheet;
    price_sheet[index][e.target.name] = e.target.value;
    this.setState({
      previous_sheet: price_sheet,
    });
  };
  onChangehandlerTwo = (index1, index2) => (e) => {
    let price_sheet = this.state.previous_sheet;
    const ferrous = ["Steel", "Stainless Steel"];
    const nonFerrous = [
      "Copper",
      "Lead",
      "Aluminum",
      "Aluminium",
      "Brass",
      "Zinc",
      "Precious Metals",
      "Electronics",
      "Freight",
    ];
    const others = ["Paper"];
    console.log("parent_category", price_sheet[index1].parent_category);
    if (price_sheet[index1].parent_category) {
      if (
        nonFerrous.some(
          (item) =>
            item.toLowerCase() ===
            price_sheet[index1].parent_category.toLowerCase()
        )
      ) {
        if (
          e.target.value == "Sold" ||
          e.target.value == "Bought" ||
          e.target.value == "Heard"
        ) {
          price_sheet[index1].source_entries[index2].volume_values = 20;
        }
        if (
          e.target.value == "Indication" ||
          e.target.value == "Bid" ||
          e.target.value == "Offer"
        ) {
          price_sheet[index1].source_entries[index2].volume_values = 5;
        }
      } else if (
        ferrous.some(
          (item) =>
            item.toLowerCase() ===
            price_sheet[index1].parent_category.toLowerCase()
        )
      ) {
        if (
          e.target.value == "Sold" ||
          e.target.value == "Bought" ||
          e.target.value == "Heard"
        ) {
          price_sheet[index1].source_entries[index2].volume_values = 500;
        }
        if (
          e.target.value == "Indication" ||
          e.target.value == "Bid" ||
          e.target.value == "Offer"
        ) {
          price_sheet[index1].source_entries[index2].volume_values = 100;
        }
      } else if (
        others.some(
          (item) =>
            item.toLowerCase() ===
            price_sheet[index1].parent_category.toLowerCase()
        )
      ) {
        if (
          e.target.value == "Sold" ||
          e.target.value == "Bought" ||
          e.target.value == "Heard"
        ) {
          price_sheet[index1].source_entries[index2].volume_values = 400;
        }
        if (
          e.target.value == "Indication" ||
          e.target.value == "Bid" ||
          e.target.value == "Offer"
        ) {
          price_sheet[index1].source_entries[index2].volume_values = 100;
        }
      }
    }

    price_sheet[index1].source_entries[index2][e.target.name] = e.target.value;

    this.setState({
      previous_sheet: price_sheet,
    });
  };
  /************************************
   * @DESC - ON SOURCE COMPANY CHANGE
   ***********************************/
  onSourceCompanyChange = (index1, index2) => (e) => {
    let price_sheet = this.state.previous_sheet;
    price_sheet[index1].source_entries[index2].source_company = JSON.parse(
      e.target.value
    );
    price_sheet[index1].source_entries[index2].source_person = "";

    const selected_source_company_id =
      price_sheet[index1].source_entries[index2].source_company._id;
    let listed_source_person =
      this.props.tradelogs.source_for_trade_logs.filter(
        (elements) => elements.source_company_id === selected_source_company_id
      );
    let all_source_person_list = !isEmpty(listed_source_person)
      ? listed_source_person[0].source_persons
      : [];

    let source = {
      _id: all_source_person_list[0].source_company_id,
      source_person_name: all_source_person_list[0].source_person_name,
    };
    price_sheet[index1].source_entries[index2].source_person = source;
    this.setState({
      previous_sheet: price_sheet,
    });
  };
  /************************************
   * @DESC - ON SOURCE PERSON CHANGE
   ***********************************/
  onSourcePersonChange = (index1, index2) => (e) => {
    let price_sheet = this.state.previous_sheet;
    price_sheet[index1].source_entries[index2].source_person = JSON.parse(
      e.target.value
    );
    this.setState({
      previous_sheet: price_sheet,
    });
  };

  /************************************
   * @DESC - Set DATE AND TIME NOW
   ***********************************/
  setDateAndTime = (index1, index2) => (e) => {
    let price_sheet = this.state.previous_sheet;
    price_sheet[index1].source_entries[index2].time = format(
      new Date(),
      "yyyy-MM-dd'T'HH:mm"
    );
    this.setState({
      previous_sheet: price_sheet,
    });
  };
  /**********************************
   * @DESC - ON CALCULATE CHANGE
   **********************************/
  onCalculateNewPrice = (index, new_price, volume) => {
    let price_sheet = this.state.previous_sheet;
    price_sheet[index].new_price_value = new_price;
    price_sheet[index].new_volume_values = volume;
    this.setState(
      {
        previous_sheet: price_sheet,
      },
      () =>
        this.props.calculate_update_pricesheet({
          priceSheet: this.state.previous_sheet,
        })
    );
  };

  /**********************************
   * @DESC - ON CALCULATE CHANGE
   **********************************/
  onEmailSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /***************************
   * @DDESC - ON SHEET UPDATE
   ***************************/
  onUpdate = (e) => {
    const formData = this.state.previous_sheet;
    this.props.update_pricesheet({ priceSheet: formData });
  };

  /***************************
   * @DDESC - ON ROLL OVER GET PRIOR PRICE AS NEW PRICE
   ***************************/
  //get also previous source entries
  rollOver = () => {
    let price_sheet = this.state.previous_sheet;
    let new_pricesheet = [];
    const source_entries_list =
      this.props.priceSheet.old_source_entries.pricesheets;
    const source_entries_map = new Map(
      source_entries_list.map((x) => [x.dpc_number, x])
    );
    for (let i = 0; i < price_sheet.length; i++) {
      const dpc_priceSheet = price_sheet[i];
      const sources_list = source_entries_map.get(dpc_priceSheet.dpc_number);
      if (!sources_list) {
        alert(`Source entries do not exist for ${dpc_priceSheet.dpc_number}`);
        continue;
      }
      delete dpc_priceSheet.source_entries;
      dpc_priceSheet.source_entries = sources_list.source_entries;
      new_pricesheet.push(dpc_priceSheet);
    }

    new_pricesheet.forEach((price) => {
      price.new_price_value = price.prior_price_value;
    });
    this.setState(
      {
        previous_sheet: new_pricesheet,
      },
      () => this.props.update_pricesheet({ priceSheet: new_pricesheet })
    );
  };
  /***************************
   * @DDESC - ON REFRESH OVER GET LATEST PRIOR PRICE
   ***************************/
  refresh = () => {
    this.props.get_refresh_pricesheet(this.props.match.params.id, () => {
      window.location.reload();
    });
  };

  /***************************
   * @DDESC - ADD SOURCES BLOCK IN PRICE ROW
   ***************************/
  addSource = (index) => {
    let price_sheet = this.state.previous_sheet;
    let block = {
      source_company: { _id: "", source_company_name: "" },
      source_person: { _id: "", source_person_name: "" },
      type: "",
      time: "",
      price_value: "",
      low_value: "",
      trend_values: "",
      volume_values: "",
      price_type: "",
      comments: "",
      author_notes: "",
    };
    let pricesheete = price_sheet[index].source_entries;
    pricesheete.push(block);
    this.setState({
      previous_sheet: price_sheet,
    });
  };

  /***************************
   * @DDESC - REMOVE/DELETE SOURCES BLOCK FROM PRICE ROW
   ***************************/
  onClear = (index1, index2) => {
    if (
      window.confirm(
        "Are you sure to want to remove source? Please calculate index again."
      )
    ) {
      let price_sheet = this.state.previous_sheet;
      let array = price_sheet[index1].source_entries.splice(index2, 1);
      this.setState({
        previous_sheet: price_sheet,
      });
    }
  };

  /***************************
   * @DDESC - DOWNLOAD PRICESHEET COPY ON DESKTOP
   ***************************/
  //depreciated
  // downloadPricesheet = () =>{
  // 	this.props.create_sheet_for_vwa({priceSheet: this.state.previous_sheet})
  // }

  /***************************
   * @DDESC - CLOSE SUCCESS MODEL
   ***************************/
  reset_method = () => {
    this.props.auth.success = false;
  };

  /***************************
   * @DDESC - Copy source to paste in another source
   ***************************/
  copySource = (index1, index2) => {
    let price_sheet = this.state.previous_sheet;
    let array = price_sheet[index1].source_entries[index2];
    this.setState({
      copyedData: array,
      copyed: true,
    });
  };

  /***************************
   * @DDESC - paste copyed source
   ***************************/
  pasteSource = (index1, index2) => {
    let price_sheet = this.state.previous_sheet;
    price_sheet[index1].source_entries[index2].source_company =
      this.state.copyedData.source_company;
    price_sheet[index1].source_entries[index2].source_person =
      this.state.copyedData.source_person;
    price_sheet[index1].source_entries[index2].type =
      this.state.copyedData.type;
    price_sheet[index1].source_entries[index2].time =
      this.state.copyedData.time;
    price_sheet[index1].source_entries[index2].price_type =
      this.state.copyedData.price_type;
    price_sheet[index1].source_entries[index2].volume_values =
      this.state.copyedData.volume_values;
    this.setState({
      previous_sheet: price_sheet,
      copyed: false,
    });
  };

  reviewFile = () => {
    const formData = this.state.previous_sheet;
    const { errors, isValid } = validatePrice(formData);
    if (isValid) {
      // if (this.state.editorEmail && this.state.editorEmail != "Select Email") {
      if (window.confirm("Are you sure to submit this sheet")) {
        this.props.send_for_review(this.props.match.params.id, formData);
      }
      // } else {
      // 	window.alert("Please add reviewer email")
      // }
    }
  };

  render() {
    if (this.props.auth.loader || this.state.loader) {
      return <Loader />;
    }
    return (
      <>
        {this.props.auth.success ? (
          <SuccessMessage
            message="pricesheet Updated"
            reset_method={this.reset_method}
          />
        ) : null}
        {this.state.previous_sheet && (
          <PriceSheetName pricesheet={this.state.previous_sheet} />
        )}
        <div className="row" id="top">
          {this.state.previous_sheet ? (
            <>
              {this.state.previous_sheet.map((price, index) => (
                <a
                  key={price.dpc_number}
                  href={`#${price.dpc_number}`}
                  className="col-md-4 price-title"
                >
                  {price.dpc_website_name}
                </a>
              ))}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="container-fluid">
          {this.state.previous_sheet ? (
            <div>
              {this.state.previous_sheet[0].status === "Draft" ? (
                <div className="btn_container">
                  <div className="d-flex">
                    <RollOver rollOver={this.rollOver} />
                    <div onClick={this.refresh} className="rollOver_btn">
                      Refresh
                    </div>
                  </div>

                  <div className="d-flex">
                    <div
                      className="rollOver_btn mr-3"
                      onClick={() => {
                        window.open(
                          `/prices/archives/${this.props.match.params.id}`,
                          "_blank"
                        );
                      }}
                    >
                      View Archive
                    </div>
                    <div
                      className="rollOver_btn"
                      onClick={() => {
                        window.open(
                          `/prices/sources/${this.props.match.params.id}`,
                          "_blank"
                        );
                      }}
                    >
                      View Sources
                    </div>
                    {/* <AddNewSource /> */}
                  </div>
                </div>
              ) : (
                <h1 className="text-center m-5">
                  Your previous pricesheet is not published yet
                </h1>
              )}
              <TradeLogArea
                state={this.state}
                onChangehandlerOne={this.onChangehandlerOne}
                onChangehandlerTwo={this.onChangehandlerTwo}
                onSourceCompanyChange={this.onSourceCompanyChange}
                onSourcePersonChange={this.onSourcePersonChange}
                setDateAndTime={this.setDateAndTime}
                onCalculateNewPrice={this.onCalculateNewPrice}
                addSource={this.addSource}
                onUpdate={this.onUpdate}
                onClear={this.onClear}
                copySource={this.copySource}
                pasteSource={this.pasteSource}
                {...this.props}
              />
              <div className="back-to-top">
                <a href="#top">
                  <i
                    className="fa fa-chevron-circle-up fa-4x"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
              {this.state.previous_sheet &&
              this.state.previous_sheet[0].status === "Draft" ? (
                <AssignAuthorAndPublishData
                  state={this.state}
                  onUpdate={this.onUpdate}
                  onEmailSelect={this.onEmailSelect}
                  downloadPricesheet={this.downloadPricesheet}
                  reviewFile={this.reviewFile}
                  {...this.props}
                />
              ) : (
                <div className="text-center mb-5">
                  <b>Pricesheet Submitted for Review</b>
                </div>
              )}
            </div>
          ) : (
            <div className="pricesheet_for_review">
              <Loader />
            </div>
          )}
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  priceSheet: state.priceSheet,
  tradelogs: state.tradelogs,
  editorialEmail: state.editorialEmail,
});

export default connect(mapStateToProps, {
  create_and_get_price_sheet,
  calculate_update_pricesheet,
  update_pricesheet,
  get_all_source_company_and_source_persons,
  get_refresh_pricesheet,
  // price_sheet_selected_trade_log,
  // get_previous_sheet,
  // get_previous_sheet_unassigned,
  // get_all_dpcs,
  // create_new_price_sheet,
  get_editorial_emails,
  get_all_pricing_reporter,
  //get_all_journalists,
  // get_latest_dpc_price,
  // create_sheet_for_vwa,
  send_for_review,
  get_old_source_entries,
})(withRouter(VolumeWeightedAverage));

export const PriceSheetName = (props) => {
  return (
    <div className="trade_log_name_container">
      <div className="sheet_name_lable">
        {props.pricesheet[0].trade_log_name}
      </div>
    </div>
  );
};

export const AssignAuthorAndPublishData = (props) => {
  return (
    <>
      <div className="assign_author_another_tradelog">
        <AssignAuthors {...props} />
        <EditorEmail {...props} />
      </div>
      <div className="buttons_for_trade_log">
        <button className="PriceSheet_Save mr-4" onClick={props.onUpdate}>
          {" "}
          Save{" "}
        </button>
        <SubmitButton onUpdate={props.submist} {...props} />
        {/* <button type="button" className="btn btn-primary ml-5" onClick={()=>props.reviewFile()}>Submit 2</button> */}
      </div>
    </>
  );
};

export const EditorEmail = (props) => {
  return (
    <>
      <div className="form-group">
        <select
          className="form-control"
          name="editorEmail"
          onChange={props.onEmailSelect}
        >
          <option>Select Email</option>
          {!isEmpty(props.editorialEmail.editorial_emails)
            ? props.editorialEmail.editorial_emails.map((emailList, index) => (
                <option key={index} value={emailList.email}>
                  {emailList.email}
                </option>
              ))
            : null}
        </select>
      </div>
    </>
  );
};

export const TradeLogArea = (props) => {
  return (
    <>
      <div className="trade_log_area_main_containers">
        {props.state.previous_sheet
          ? props.state.previous_sheet.map((data, index) => (
              <div
                className="single_row_trade_log_area"
                key={index}
                id={data.dpc_number}
              >
                <CalculatedSection data={data} index={index} {...props} />
                {data.source_entries.map((data_s, index2) => (
                  <DataSection
                    key={index2}
                    index1={index}
                    index2={index2}
                    data_s={data_s}
                    priceSheetProps={props.state.previous_sheet}
                    {...props}
                  />
                ))}
                <div className="add_sources_btn">
                  <i
                    className="fa fa-plus-circle"
                    aria-hidden="true"
                    onClick={() => props.addSource(index)}
                  ></i>
                </div>
              </div>
            ))
          : null}
      </div>
    </>
  );
};

export const CalculatedSection = (props) => {
  let overRide = false;
  if (
    !isEmpty(props.data.new_price_value) &&
    !isEmpty(props.data.prior_price_value)
  ) {
    let trend = parseFloat(
      (props.data.new_price_value - props.data.prior_price_value) /
        (props.data.prior_price_value / 100)
    ).toFixed(2);

    if (trend > 20 || trend < -20) {
      overRide = true;
    }
  }

  return (
    <div className="calculated_section_main_container">
      <div className="calculated_section_blank_space">
        {overRide ? (
          <CalculatedTrendSection
            data={props.data}
            index={props.index}
            onChange={props.onChangehandlerOne}
          />
        ) : null}
      </div>
      <div className="calculated_section_inputs">
        <DPCName dpc_website_name={props.data.dpc_website_name} />
        <CalculateSection
          data={props.data}
          displayCalculate="Draft"
          {...props}
        />
        <NewValuesSection
          data={props.data}
          index={props.index}
          onChange={props.onChangehandlerOne}
        />
      </div>
      <div className="calculated_section_comments_main_container">
        <CalculateCommentSection
          data={props.data}
          index={props.index}
          onChange={props.onChangehandlerOne}
        />
      </div>
    </div>
  );
};

export const DataSection = (props) => {
  return (
    <div className="data_section_main_container">
      <div className="data_section_block_section">
        <SelectSourceCompany {...props} />
        <SelectSourcePerson {...props} />
        <SelectTypeAndTime {...props} />
      </div>
      <div className="data_section_block_section">
        <SourcePriceType {...props} />
        <SourceInputContainer {...props} />
      </div>
      <div className="data_section_block_section">
        <SourceCommentsContainer
          data={props.data_s}
          onChange={props.onChangehandlerTwo(props.index1, props.index2)}
        />
      </div>
    </div>
  );
};
