import React from 'react';
import { parseISO, format } from 'date-fns';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { delete_post } from '../../../store/actions/postAction';

const RenderPost = (props) => {

    const deletePost = (id) => {
        if (window.confirm('Are you sure you want to DELETE this post')) {
            const formData = {
                post_type: props.post.post_type,
                _id: props.auth.user._id,
                fname: props.auth.user.fname,
                lname: props.auth.user.lname
            }
            props.delete_post(id, formData);
        }
    }
    let path = props.location.pathname.split("/")
    const content_created_on = format(parseISO(props.post.content_created_on, 'yyyy-MM-dd', new Date()), 'dd-MMM-yyyy hh:mm a');
    const content_last_updated_on = props.post.content_last_updated_on
        ? format(parseISO(props.post.content_last_updated_on.date), "dd-MMM-yyyy hh:mm a")
        : "N/A"; // Handle cases where updated date is missing
    
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='render_post_main_container'>
                    <div className='headline_and_button_section'>
                        <div className='post_headline'>{props.post.headline ? props.post.headline.substring(0, 100) : "Untitled Headline . . "}</div>
                        {props.post.content_status === 'Draft' && <div className="delete-draft" onClick={() => deletePost(props.post._id)}> <i className="fa fa-trash fa-2x" aria-hidden="true"></i> </div>}
                        <button style={{ border: "none" }} onClick={props.onClick} className={`post_buttons ${props.background} text-uppercase`} disabled={props.post.content_status === "Assigned" && path[path.length - 1] !== "edit-all" ? true : false}>{props.post.content_status}</button>
                    </div>
                    <div className={`post_info_section`}>
                        Author : {props.post.author.fname + " " + props.post.author.lname}&emsp;|&emsp;
                        {/* Date created : {content_created_on} */}
                        {props.sortBy === "content_last_updated_on.date" ? (
                            <>Date Updated: {content_last_updated_on}</>
                        ) : (
                            <>Date Created: {content_created_on}</>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.errors,
    sortBy: state.posts.sortBy,  // Ensure sorting is dynamic
    sortOrder: state.posts.sortOrder
})
export default connect(mapStateToProps, { delete_post })(withRouter(RenderPost));

