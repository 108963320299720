import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_post, edit_mode_toggler, update_post } from '../../../../store/actions/postAction';
import isEmpty from '../../../../utils/isEmpty';
import {
    get_sub_authors,
    get_sub_editors
} from '../../../../store/actions/authAction';
import { post_validator } from '../AuthorPostArea/Validator/postValidator';

import Backbutton from '../ResusableComponents/BackButton';
import PostType from '../ResusableComponents/PostType';
import Publications from '../ResusableComponents/Publications';
import Headline from '../ResusableComponents/Headline';
import Summary from '../ResusableComponents/Summary';
import EditorAndChat from '../ResusableComponents/EditorAndChat';
import FeaturedImage from '../ResusableComponents/FeaturedImage';
import CategoryTag from '../ResusableComponents/CategoryTags';
import PostLogs from '../ResusableComponents/PostLogs';
import AuthorNotes from '../ResusableComponents/AuthorNotes';
import SubEditor from '../ResusableComponents/SubEditor';
import Preview from '../ResusableComponents/PreviewButton';
import CloseButton from '../ResusableComponents/CloseButton';
// import SubmitButton from '../ResusableComponents/SubmitButton';
import Footer from '../../../Common/Footer';
import UrlOfAssociation from '../ResusableComponents/url_of_association';
import EventDate from '../ResusableComponents/EventDates';
import Publish from '../ResusableComponents/Publish';
import Reject from '../ResusableComponents/Reject';
import store from '../../../../store/store';
import { GET_TO_EDIT_POST } from '../../../../store/types';

export class EditorPostArea extends Component {
    constructor() {
        super();
        this.state = {
            _id: "",
            post_type: "",
            selected_publications: [],
            headline: "",
            summary: "",
            content: "",
            featured_image: "",
            url_of_assoication: "",
            start_date_of_event: "",
            end_date_of_event: "",
            category_tags: [],
            category_tags_input: "",
            author_notes: "",
            content_status: "",
            sub_authors: [],
            editor: {},
            sub_editor: [],
            url: "",
            paid_content: true,
            hasChanges: false, // Track changes for autosave
        };
        this.autosaveInterval = null;
        this.debounceTimer = null;
    }

    /*****************************
     * @DESC - LIFE CYCLE METHODS
     *****************************/
    componentDidMount() {
        store.dispatch({ type: GET_TO_EDIT_POST, payload: {} });
        this.props.get_post(this.props.match.params.id);
        // this.autosave = setInterval(this.onSubmit, 1000);
        // this.get_subtauthors = setInterval( this.get_sub_authors, 1000 );

        // Directly call get_sub_authors instead of using setInterval
        this.get_sub_authors();

        this.startAutosave();
    }

    componentDidUpdate(prevProps, prevState) {
        // Fetch sub-authors only if selected_publications changes
        if (prevState.selected_publications !== this.state.selected_publications) {
            this.get_sub_authors();
        }
        // Detect changes in the post content
        if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
            this.setState({ hasChanges: true });
            this.debouncedSave();
        }
    }

    componentWillUnmount() {
        // clearInterval(this.autosave);
        // clearInterval( this.get_subtauthors );

        this.stopAutosave();
        store.dispatch({ type: GET_TO_EDIT_POST, payload: {} });
    }

    /*****************************
    * AUTOSAVE LOGIC
    *****************************/
    startAutosave = () => {
        // Backup autosave every 60s in case debounce doesn't trigger
        this.autosaveInterval = setInterval(() => {
            if (this.state.hasChanges) {
                this.savePost();
            }
        }, 60000);
    };

    stopAutosave = () => {
        clearInterval(this.autosaveInterval);
        clearTimeout(this.debounceTimer);
    };

    debouncedSave = () => {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
            this.savePost();
        }, 3000); // 3s delay to prevent excessive API calls
    };

    savePost = () => {
        if (this.state._id && this.state.hasChanges) {
            this.props.update_post(this.state, () => {
                this.setState({ hasChanges: false }); // Reset after successful save
            });
        }
    };

    /*****************************
    * HANDLING USER INPUT
    *****************************/
    // handleChange = (e) => {
    //     this.setState({ [e.target.name]: e.target.value, hasChanges: true });
    //     this.debouncedSave(); // Autosave after typing stops for 3s
    //     console.log("EditorPostArea.js : handleChange() CALLED. ");
    // };

    // removeAutoSave = e => {
    //     clearInterval(this.autosave);
    // }
    // startAutoSave = e => {
    //     this.autosave = setInterval(this.onSubmit, 1000);
    // }

    removeAutoSave = () => {
        this.stopAutosave();
    };

    startAutoSave = () => {
        this.startAutosave();
    };

    static getDerivedStateFromProps(nextProps, nextState) {
        // console.log( post.selected_publications, nextState.selected_publications );
        const post = nextProps.posts.post_to_edit;
        if ((!isEmpty(post) && !nextState.user_has_not_set_data) || (post._id !== nextState._id)) {
            let dateupdated = post.content_to_be_published_on_date ? post.content_to_be_published_on_date.split("T")[0].split("-") : null;

            dateupdated = post.content_to_be_published_on_date ? `${dateupdated[2]}-${dateupdated[1]}-${dateupdated[0]}` :
                post.content_to_be_published_on_date ? post.content_to_be_published_on_date.split("T")[0].split("-").reverse().join("-") : null;

            let heading = post.headline ? post.headline.trim().replace(/[^a-zA-Z0-9/ ]/g, "").replace("/", "-").replace(/ +(?= )/g, '').split(" ").join("-") : "";

            return {
                user_has_not_set_data: true,
                _id: post._id,
                post_type: post.post_type,
                selected_publications: post.selected_publications,
                headline: post.headline ? post.headline : "",
                summary: post.summary ? post.summary : "",
                content: post.content ? post.content : "",
                featured_image: post.featured_image ? post.featured_image : "",
                url_of_assoication: post.url_of_assoication ? post.url_of_assoication : "",
                start_date_of_event: post.start_date_of_event ? post.start_date_of_event : "",
                end_date_of_event: post.end_date_of_event ? post.end_date_of_event : "",
                category_tags: post.category_tags ? post.category_tags : [],
                category_tags_input: post.category_tags ? post.category_tags.toString() : "",
                author_notes: post.author_notes ? post.author_notes : "",
                content_status: post.content_status === "Unassigned" ? "Assigned" : post.content_status,
                sub_authors: post.sub_authors ? post.sub_authors : [],
                sub_editor: post.sub_editor ? post.sub_editor : [],
                editor: post.editor ? post.editor : { _id: nextProps.auth.user._id, fname: nextProps.auth.user.fname, lname: nextProps.auth.user.lname, profileImg: nextProps.auth.user.profileImg },
                url: `${dateupdated}/${heading}`,
                paid_content: post.paid_content ? post.paid_content : true,
            }
        }
        return null
    }

    /***********************************
     * @DESC - GET SUB AUTHORS
     **********************************/
    get_sub_authors = () => {
        // console.log( this.state.selected_publications );
        // Only proceed if selected_publications is not empty
        if (!isEmpty(this.state.selected_publications)) {
            this.props.get_sub_authors(this.state.selected_publications);
            this.props.get_sub_editors(this.state.selected_publications);
        }
    }

    /************************************
     * @DESC - PUBLICATION CHANGE HANDLER
     ************************************/
    onPublicationHandler = publication => e => {
        let selected_publications = this.state.selected_publications;
        let return_value = this.isPublicationPresent(publication);
        if (return_value || return_value === 0) {
            selected_publications.splice(return_value, 1);
        } else {
            selected_publications.push(publication);
        }
        this.setState({
            selected_publications: selected_publications
        })
    }

    isPublicationPresent = (publication) => {
        let obj = this.state.selected_publications.find(prevPublication => prevPublication._id === publication._id);
        return obj ? this.state.selected_publications.indexOf(obj) : false;
    }

    /*****************************
     * @DESC - SUB AUTHOR SELECTOR
     ****************************/
    onSubAuthorHandler = publication => e => {
        let sub_authors = this.state.sub_authors;
        let return_value = this.isSubAuthorPresent(publication);
        if (return_value || return_value === 0) {
            sub_authors.splice(return_value, 1);
        } else {
            sub_authors.push(publication);
        }
        this.setState({
            sub_authors: sub_authors
        })
    }

    isSubAuthorPresent = (publication) => {
        let obj = this.state.sub_authors.find(prevPublication => prevPublication._id === publication._id);
        return obj ? this.state.sub_authors.indexOf(obj) : false;
    }

    //EDITOR
    onSubEditorHandler = publication => e => {
        let sub_editor = this.state.sub_editor;
        let return_value = this.isSubEditorPresent(publication);
        if (return_value || return_value === 0) {
            sub_editor.splice(return_value, 1);
        } else {
            sub_editor.push(publication);
        }
        this.setState({
            sub_editor: sub_editor
        })
    }

    isSubEditorPresent = (publication) => {
        let obj = this.state.sub_editor.find(prevPublication => prevPublication._id === publication._id);
        return obj ? this.state.sub_editor.indexOf(obj) : false;
    }

    /**************************
     * @DESC - ONCHANGE METHODS
     **************************/
    onChange = e => {
        let flag = true;
        if (e.target.name === "headline" && e.target.value.length > 110) { flag = false }
        if (e.target.name === "summary" && e.target.value.length > 280) { flag = false }

        if (e.target.name == "headline") {

            let post = this.props.posts.post_to_edit
            let dateupdated = post.content_to_be_published_on_date ? post.content_to_be_published_on_date.split("T")[0].split("-") : null;
            dateupdated = post.content_to_be_published_on_date ? `${dateupdated[2]}-${dateupdated[1]}-${dateupdated[0]}` :
                post.content_to_be_published_on_date ? post.content_to_be_published_on_date.split("T")[0].split("-").reverse().join("-") : null;
            let heading = post.headline ? post.headline.trim().replace(/[^a-zA-Z0-9/$& ]/g, "").replace("/", "-").replace(/ +(?= )/g, '').split(" ").join("-") : "";

            this.setState({
                url: `${dateupdated}/${heading}`
            })
        }

        if (flag) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    /************************************
     * @DESC - ONCHANGE FOR EDITOR WINDOW
     ************************************/
    onEditorChange = (editor) => {
        var data = editor.getData();
        var charCount = this.calculate_character_count(data);
        var wordCount = this.calculate_word_count(data);
        // if( charCount <= 12000 && wordCount <= 1300 ){
        this.setState({
            content: data
        });
        // } else {
        //     window.alert('Character length Exceeded, Not more that 160 characters!!');
        // }
    }

    /****************************************
     * @DESC - CATEGORY TAGS ONCHANGE HANDLER
     ***************************************/
    onCategoryChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            category_tags: e.target.value.split(',')
        })
    }

    /****************************
     * @DESC - WORD COUNT
     ***************************/
    calculate_word_count = (data) => {
        if (!data || data === "") { return 0 }
        else {
            data = data.replace(/<[^>]*>/g, " ").replace(/\s+/g, ' ').trim().split(" ").length;
            return data
        }
    }
    /*****************************
     * @DESC - CHARACTER COUNT
     *****************************/
    calculate_character_count = (data) => {
        if (!data || data === "") { return 0 }
        else {
            data = data.replace(/<[^>]*>/g, " ").replace(/\s+/g, ' ').replace('&nbsp;', ' ').trim().length;
            return data;
        }
    }

    /*******************************************
     * @DESC - SAVE METHOD - CLOSE AND AUTO SAVE
     *******************************************/
    // onSubmit = e => {
    //     let formData = this.state;
    //     // if( formData.editor ){
    //     //     formData.editor = JSON.parse( formData.editor );
    //     // }
    //     if (formData._id) {
    //         this.props.update_post(formData, null);
    //     }
    // }
    onSubmit = e => {
        let formData = this.state;
        if (formData._id && this.state.hasChanges) { // Only call if changes exist
            this.props.update_post(formData, null);
            this.setState({ hasChanges: false }); // Reset the changes flag after saving
        }
    };

    /********************************************
     * @DESC- CLOSE BUTTON MEHTHODS
     *******************************************/
    // onClose = e => {

    //     let post = this.props.posts.post_to_edit
    //     let dateupdated = post.content_to_be_published_on_date ? post.content_to_be_published_on_date.split("T")[0].split("-") : null;
    //     dateupdated = post.content_to_be_published_on_date ? `${dateupdated[2]}-${dateupdated[1]}-${dateupdated[0]}` :
    //         post.content_to_be_published_on_date ? post.content_to_be_published_on_date.split("T")[0].split("-").reverse().join("-") : null;
    //     let heading = post.headline ? post.headline.trim().replace(/[^a-zA-Z0-9/$& ]/g, "").replace("/", "-").replace(/ +(?= )/g, '').split(" ").join("-") : "";

    //     this.setState({
    //         url: `${dateupdated}/${heading}`
    //     }, () => {
    //         let formData = this.state;
    //         if (formData._id) {
    //             if (window.confirm("Are you sure to close")) {
    //                 this.props.update_post(formData, window.history.back(), store.dispatch({ type: GET_TO_EDIT_POST, payload: {} }));
    //             }
    //         }
    //     })

    // }

    onClose = e => {
        let post = this.props.posts.post_to_edit;
        let dateupdated = post.content_to_be_published_on_date
            ? post.content_to_be_published_on_date.split("T")[0].split("-")
            : null;
        dateupdated = post.content_to_be_published_on_date
            ? `${dateupdated[2]}-${dateupdated[1]}-${dateupdated[0]}`
            : null;

        let heading = post.headline
            ? post.headline.trim().replace(/[^a-zA-Z0-9/$& ]/g, "").replace("/", "-").replace(/ +(?= )/g, '').split(" ").join("-")
            : "";

        this.setState(
            {
                url: `${dateupdated}/${heading}`,
            },
            () => {
                let formData = this.state;
                if (formData._id) {
                    if (window.confirm("Are you sure to close")) {
                        this.props.update_post(
                            formData,
                            () => window.history.back(), // Pass as a function reference
                            () => store.dispatch({ type: GET_TO_EDIT_POST, payload: {} }) // Pass as a function reference
                        );
                    }
                }
            }
        );
    };


    SaveArticle = e => {
        let post = this.props.posts.post_to_edit
        let dateupdated = post.content_to_be_published_on_date ? post.content_to_be_published_on_date.split("T")[0].split("-") : null;
        dateupdated = post.content_to_be_published_on_date ? `${dateupdated[2]}-${dateupdated[1]}-${dateupdated[0]}` :
            post.content_to_be_published_on_date ? post.content_to_be_published_on_date.split("T")[0].split("-").reverse().join("-") : null;
        let heading = post.headline ? post.headline.trim().replace(/[^a-zA-Z0-9/$& ]/g, "").replace("/", "-").replace(/ +(?= )/g, '').split(" ").join("-") : "";

        this.setState({
            url: `${dateupdated}/${heading}`
        }, () => {
            let formData = this.state;
            if (formData._id) {
                this.props.update_post(formData, store.dispatch({ type: GET_TO_EDIT_POST, payload: {} }));
            }
        })
    }

    /**********************************************
     * @DESC - SUBMIT BUTTON TO EDITOR
     **********************************************/
    onSubmittoEditor = (content_status, editor) => e => {
        let formData = this.state;
        let response = post_validator(formData);
        if (!response) {
            window.alert('Please fill all the elements in to submit a post');
        } else {
            if (content_status === "Unassigned") {
                formData.content_status = "Unassigned";
                this.props.update_post(formData, this.props.history.goBack());
            } else {
                formData.content_status = "Assigned";
                formData.editor = JSON.parse(formData.editor);
                this.props.update_post(formData, this.props.history.goBack(), store.dispatch({ type: GET_TO_EDIT_POST, payload: {} }));
            }
        }
    }

    /************************************
     * @DESC - FEATURED IMAGE
     ************************************/
    featured_image_change = (url) => {
        console.log(url);
        this.setState({
            featured_image: url
        });
    }
    /*************************************
     * @DESC - SEND BACK TO AUTHOR
     ************************************/
    // send_back_to_author = e => {
    //     let formData = this.state;
    //     formData.content_status = "Update";
    //     if (formData._id) {
    //         this.props.update_post(formData, this.props.history.goBack(), store.dispatch({ type: GET_TO_EDIT_POST, payload: {} }));
    //         window.alert("Post has been sent back to author");
    //     }
    // }
    send_back_to_author = e => {
        let formData = this.state;
        formData.content_status = "Update";
        if (formData._id) {
            this.props.update_post(
                formData,
                () => this.props.history.goBack(), // Callback as function
                () => store.dispatch({ type: GET_TO_EDIT_POST, payload: {} }) // Callback as function
            );
            window.alert("Post has been sent back to author");
        }
    };
    /*************************************
     * @DESC - REJECT POST
     ************************************/
    // reject_post = e => {
    //     let formData = this.state;
    //     formData.content_status = "Rejected";
    //     if (formData._id) {
    //         this.props.update_post(formData, this.props.history.goBack(), store.dispatch({ type: GET_TO_EDIT_POST, payload: {} }));
    //         window.alert("Post has been rejected successfully");
    //     }
    // }
    reject_post = e => {
        let formData = this.state;
        formData.content_status = "Rejected";
        if (formData._id) {
            this.props.update_post(
                formData,
                () => this.props.history.goBack(), // Callback as function
                () => store.dispatch({ type: GET_TO_EDIT_POST, payload: {} }) // Callback as function
            );
            window.alert("Post has been rejected successfully");
        }
    };

    /**********************************
     * @DESC - ONCATEGORY SELECT
     *********************************/
    onDropDownSelect = (name, value) => e => {
        console.log("value", value);

        let category_tags = this.state.category_tags;
        let response = this.isCateogoryTagPresent(value);
        if (response === 0 || response) {
            console.log("value1", value);
            category_tags.splice(response, 1);
        } else {
            switch (value) {
                case "Aluminum":
                    const Aluminum = ["Nonferrous", "Aluminum"];
                    category_tags = [...new Set([...category_tags, ...Aluminum])];
                    break;

                case "Americas":
                    const Americas = ["Nonferrous", "Aluminum"]
                    category_tags = [...new Set([...category_tags, ...Americas])];
                    break;

                case "Brass":
                    const Brass = ["Nonferrous", "Brass"]
                    category_tags = [...new Set([...category_tags, ...Brass])];
                    break;

                case "Canada":
                    const Canada = ["World", "North America", "Canada"]
                    category_tags = [...new Set([...category_tags, ...Canada])];
                    break;

                case "China":
                    const China = ["World", "Asia", "China"]
                    category_tags = [...new Set([...category_tags, ...China])];
                    break;

                case "Copper/brass":
                    const Copper = ["Nonferrous", "Copper/brass"]
                    category_tags = [...new Set([...category_tags, ...Copper])];
                    break;

                case "Commentary":
                    const Commentary = ["Commentary"]
                    category_tags = [...new Set([...category_tags, ...Commentary])];
                    break;

                case "India":
                    const India = ["World", "Asia", "India"]
                    category_tags = [...new Set([...category_tags, ...India])];
                    break;

                case "Lead":
                    const Lead = ["Nonferrous", "Lead"]
                    category_tags = [...new Set([...category_tags, ...Lead])];
                    break;

                case "Mexico":
                    const Mexico = ["World", "North America", "Mexico"]
                    category_tags = [...new Set([...category_tags, ...Mexico])];
                    break;

                case "Minor metals":
                    const MinorMetals = ["Nonferrous", "Minor metals"]
                    category_tags = [...new Set([...category_tags, ...MinorMetals])];
                    break;

                case "News":
                    const News = ["News"]
                    category_tags = [...new Set([...category_tags, ...News])];
                    break;

                case "Nickel":
                    const Nickel = ["Nonferrous", "Nickel"]
                    category_tags = [...new Set([...category_tags, ...Nickel])];
                    break;

                case "Precious metals":
                    const PreciousMetals = ["Nonferrous", "Precious metals"]
                    category_tags = [...new Set([...category_tags, ...PreciousMetals])];
                    break;

                case "Stainless steel":
                    const StainlessSteel = ["Ferrous", "Stainless steel"]
                    category_tags = [...new Set([...category_tags, ...StainlessSteel])];
                    break;

                case "Steel":
                    const Steel = ["Ferrous", "Steel"]
                    category_tags = [...new Set([...category_tags, ...Steel])];
                    break;

                case "USA":
                    const USA = ["World", "North America", "USA"]
                    category_tags = [...new Set([...category_tags, ...USA])];
                    break;

                case "Zinc":
                    const Zinc = ["Nonferrous", "Zinc"]
                    category_tags = [...new Set([...category_tags, ...Zinc])];
                    break;

                //=========================================================
                case "Africa":
                    const Africa = ["World", "Africa"]
                    category_tags = [...new Set([...category_tags, ...Africa])];
                    break;

                case "Central America":
                    const CentralAmerica = ["World", "Central America"]
                    category_tags = [...new Set([...category_tags, ...CentralAmerica])];
                    break;

                case "Europe":
                    const Europe = ["World", "Europe"]
                    category_tags = [...new Set([...category_tags, ...Europe])];
                    break;

                case "Middle East":
                    const MiddleEast = ["World", "Middle East"]
                    category_tags = [...new Set([...category_tags, ...MiddleEast])];
                    break;

                case "Russia/CIS":
                    const RussiaCIS = ["World", "Russia/CIS"]
                    category_tags = [...new Set([...category_tags, ...RussiaCIS])];
                    break;

                case "South America":
                    const SouthAmerica = ["World", "South America"]
                    category_tags = [...new Set([...category_tags, ...SouthAmerica])];
                    break;

                case "Turkey":
                    const Turkey = ["World", "Turkey"]
                    category_tags = [...new Set([...category_tags, ...Turkey])];
                    break;

                case "UK":
                    const UK = ["World", "UK"]
                    category_tags = [...new Set([...category_tags, ...UK])];
                    break;

                case "North America":
                    const NorthAmerica = ["World", "North America"]
                    category_tags = [...new Set([...category_tags, ...NorthAmerica])];
                    break;

                case "Asia":
                    const Asia = ["World", "Asia"]
                    category_tags = [...new Set([...category_tags, ...Asia])];
                    break;

                default:
                    category_tags.push(value);
                    break;
            }
        }
        this.setState({
            name: value.replace(/<[^>]*>/g, " ").replace(/\s+/g, ' ').trim(),
            category_tags: category_tags
        });
    }
    /*************************************
     * @DESC - CATEGORY TAGS 
     *************************************/
    isCateogoryTagPresent = (value) => {
        let obj = this.state.category_tags.find(category => category === value);
        return obj ? this.state.category_tags.indexOf(obj) : false;
    }
    /***************************************
     * @DESC - ON DELETE CATEOGRY
     ***************************************/
    onDeleteCategory = (value) => e => {
        let category_tags = this.state.category_tags;
        console.log("value", value);
        let response = this.isCateogoryTagPresent(value);
        if (response || response === 0) {
            category_tags.splice(response, 1);
        }
        this.setState({
            category_tags: category_tags
        })
    }

    render() {
        if (isEmpty(this.state._id)) {
            return null;
        }
        return (
            <>
                <Backbutton />
                <PostType post_type={this.state.post_type} />
                <Publications
                    publications={this.props.auth.user.assigned_publications}
                    state={this.state}
                    onPublicationHandler={this.onPublicationHandler}
                    isPublicationPresent={this.isPublicationPresent}
                    onSubmit={this.onSubmit}
                />
                <Headline headline={this.state.headline} onChange={this.onChange} character_count={this.calculate_character_count} />
                <Summary summary={this.state.summary} onChange={this.onChange} character_count={this.calculate_character_count} />
                <EditorAndChat
                    content={this.state.content}
                    onEditorChange={this.onEditorChange}
                    character_count={this.calculate_character_count}
                    word_count={this.calculate_word_count}
                    post_id={this.state._id}
                />
                <FeaturedImage
                    featured_image={this.state.featured_image}
                    onChange={this.featured_image_change}
                />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='main_container_category_and_others'>
                                <div className='category_author_notes'>

                                    <CategoryTag
                                        category_tags={this.state.category_tags}
                                        category_tags_input={this.state.category_tags_input}
                                        onChange={this.onCategoryChange}
                                        onDropDownSelect={this.onDropDownSelect}
                                        onDeleteCategory={this.onDeleteCategory}
                                    />
                                    <AuthorNotes
                                        author_notes={this.state.author_notes}
                                        onChange={this.onChange}
                                    />
                                    <SubEditor
                                        sub_author_list={this.props.auth.sub_editor}
                                        sub_editor={this.state.sub_editor}
                                        onSubEditorHandler={this.onSubEditorHandler}
                                        isSubEditorPresent={this.isSubEditorPresent}
                                    />
                                    {
                                        this.state.post_type === "Association News" ?
                                            <UrlOfAssociation
                                                url_of_assoication={this.state.url_of_assoication}
                                                onChange={this.onChange}
                                            /> : null
                                    }
                                    {
                                        this.state.post_type === "Events" ?
                                            <EventDate
                                                start_date_of_event={this.state.start_date_of_event}
                                                end_date_of_event={this.state.end_date_of_event}
                                                onChange={this.onChange}
                                            /> : null
                                    }


                                </div>
                                <div className='post_logs'>
                                    <PostLogs
                                        post_id={this.state._id}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='send_back_to_author'>
                                <div className='send_back_lable' onClick={this.send_back_to_author} >Send post back to author!!</div>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='author_buttons'>
                                <div className='preview_button_position'>
                                    <Preview state={this.state} /> &emsp;
                                    <Reject onClick={this.reject_post} />
                                </div>
                                <div className='close_button_position'><CloseButton
                                    onClick={this.onClose}
                                /></div>
                                <div className='cursor-pointer button_green  mr-5'
                                    onClick={this.SaveArticle}
                                >Save</div>
                                <div className='submit_button_position'>
                                    <Publish
                                        onClick={this.SaveArticle}
                                        SaveArticle={this.SaveArticle}
                                        post={this.props.posts.post_to_edit}
                                        startAutoSave={this.startAutoSave}
                                        removeAutoSave={this.removeAutoSave}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.errors,
    posts: state.posts
});

export default connect(mapStateToProps, {
    get_post,
    edit_mode_toggler,
    get_sub_authors,
    update_post,
    get_sub_editors
})(withRouter(EditorPostArea));

